<template>
    <div class="texno-khan-o">
        <div class="card-table-body ifon-add-title-block">
            <el-row :gutter="20">
                <el-col :span="24">
                    <div class="caz-blocks-sarcho-title">
                        <div
                            class="content-title d-flex align-center mr-2"
                            :class="
                                mode
                                    ? 'content__titleday'
                                    : 'content__titlenight'
                            "
                        >
                            Товары
                        </div>
                        <div class="block-sarche">
                            <div class="header__search d-flex">
                                <crm-input
                                    :size="'small'"
                                    :className="'w100'"
                                    :class="
                                        mode ? 'input__day' : 'input__night'
                                    "
                                    v-model="search"
                                    :icon="'el-icon-search'"
                                    class="mr-3"
                                    style="width: 220px"
                                ></crm-input>
                                <el-date-picker
                                    v-model="value1"
                                    :class="
                                        mode ? 'input__day' : 'input__night'
                                    "
                                    size="small"
                                    type="date"
                                    class="mr-3"
                                    placeholder="От"
                                >
                                </el-date-picker>
                                <el-date-picker
                                    v-model="value1"
                                    size="small"
                                    :class="
                                        mode ? 'input__day' : 'input__night'
                                    "
                                    type="date"
                                    placeholder="До"
                                >
                                </el-date-picker>
                            </div>
                        </div>
                    </div>
                </el-col>
                <el-col :span="24">
                    <el-breadcrumb separator="/">
                        <el-breadcrumb-item
                            class="text-white"
                            :to="{ path: '/' }"
                        >
                            Home
                        </el-breadcrumb-item>
                        <el-breadcrumb-item :to="{ path: 'regionsProducts' }">
                            Регионы
                        </el-breadcrumb-item>
                        <el-breadcrumb-item :to="{ path: 'regionDealer' }">
                            Ферганская область
                        </el-breadcrumb-item>
                        <el-breadcrumb-item>
                            Ozodbek Umarov
                        </el-breadcrumb-item>
                    </el-breadcrumb>
                </el-col>
            </el-row>
        </div>
        <!-- end ifon-add-title-block -->
        <el-row :gutter="10">
            <el-col :sm="24" :md="12" :lg="6" :xl="6">
                <router-link :to="{ name: 'dealerProduct' }">
                    <div class="task-home-two-left texno-khan">
                        <div>
                            <div
                                :class="
                                    mode ? 'style-scuerday' : 'style-scuernight'
                                "
                                class="style-scuer mb-3"
                            >
                                <div
                                    class="
                                        sausage-chart__item_body_top
                                        text-white
                                        mt-2
                                        mb-2
                                    "
                                >
                                    <i class="el-icon-discount"></i>
                                    Магазин
                                </div>
                                <ul class="i-ul-texno">
                                    <li class="text-white">
                                        <i class="el-icon-box"></i>
                                        Товары: <b>5 400</b>
                                    </li>
                                    <li class="text-white text-center">
                                        <i class="el-icon-location-outline"></i>
                                        Адрес:
                                        <b>
                                            Ташкент, Шайхантаурский район, Улица
                                            Караташ, 11А
                                        </b>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </router-link>
            </el-col>
            <!-- end col -->
        </el-row>
    </div>
</template>
<script>
export default {
    name: "dealer",
};
</script>
